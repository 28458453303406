import { GetterTree } from 'vuex';
import { UPayGettersTypes, UPayStateTypes, IRootState } from './../../interfaces';

export const getters: GetterTree<UPayStateTypes, IRootState> & UPayGettersTypes = {
    upayAppUser: (state: UPayStateTypes) => {
        return state.upayAppUser;
    },
    upayAdminUser: (state: UPayStateTypes) => {
        return state.upayAdminUser;
    },
};
