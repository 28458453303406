import { ActionTree } from 'vuex';
import { ActionTypes } from './action-types';
import { InquiryActionsTypes, InquiryStateTypes, IRootState, AuthUser, InquiryNewStoreParam, InquiryRecommendParam } from '@/store/interfaces';
import apiClient from '@/client/api';
import { AxiosError } from 'axios';

export const actions: ActionTree<InquiryStateTypes, IRootState> & InquiryActionsTypes = {
    /*
     * 新規出店のお問合せ
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.INQUIRY_NEWSTORE]({ commit }, payload: { auth: AuthUser; param: InquiryNewStoreParam }): Promise<boolean> {
        try {
            await apiClient.post('/inquiry/newstore', payload.param, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            return true;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return false;
        }
    },

    /*
     * おすすめサービスのお問合せ
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.INQUIRY_RECOMMEND]({ commit }, payload: { auth: AuthUser; param: InquiryRecommendParam }): Promise<boolean> {
        try {
            await apiClient.post('/inquiry/recommend', payload.param, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            return true;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.response?.status);
            console.error(err.response?.statusText);
            return false;
        }
    },
};
