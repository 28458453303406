import { Module } from 'vuex';
import { InquiryStateTypes, IRootState } from '@/store/interfaces';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';

// Module
const inquiry: Module<InquiryStateTypes, IRootState> = {
    state,
    getters,
    mutations,
    actions,
};

export default inquiry;
