import { AuthStateTypes } from './../../interfaces';

export const state: AuthStateTypes = {
    auth: undefined,
    authUser: undefined,
    unisCustomer: undefined,
    confirmationResult: undefined,
    registerParams: undefined,
    resetAccount: undefined,
    resetDestination: undefined,
    resetParams: undefined,
    registerPartParams: undefined,
    limitedData: undefined,
    encryptedData: undefined,
    isSalesDemoUser: false,
    entryParams: undefined,
    entryChallengeUmid: undefined,
    entryProvisionalUmid: undefined,
    setIdPassParams: undefined,
    forgotRequestParams: undefined,
};
