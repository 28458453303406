export enum MutationTypes {
    SET_AUTH = 'SET_AUTH',
    SET_AUTH_USER = 'SET_AUTH_USER',
    SET_UNIS_CUSTOMER = 'SET_UNIS_CUSTOMER',
    SET_CONFIRMATION_RESULT = 'SET_CONFIRMATION_RESULT',
    SET_REGISTER_PARAMS = 'SET_REGISTER_PARAMS',
    SET_REGISTER_PART_PARAMS = 'SET_REGISTER_PART_PARAMS',
    SET_SELECT_RESET_ACCOUNT = 'SET_SELECT_RESET_ACCOUNT',
    SET_RESET_DESTINATION = 'SET_RESET_DESTINATION',
    SET_RESET_PARAMS = 'SET_RESET_PARAMS',
    SET_LIMITED_DATA = 'SET_LIMITED_DATA',
    SET_ENCRYPTED_DATA = 'SET_ENCRYPTED_DATA',
    SET_ENTRY_PARAMS = 'SET_ENTRY_PARAMS',
    SET_ENTRY_CHALLENGE_UMID = 'SET_ENTRY_CHALLENGE_UMID',
    SET_ENTRY_PROVISIONAL_UMID = 'SET_ENTRY_PROVISIONAL_UMID',
    SET_ID_PASS_PARAMS = 'SET_ID_PASS_PARAMS',
    SET_FORGOT_REQUEST_PARAMS = 'SET_FORGOT_REQUEST_PARAMS',
}
