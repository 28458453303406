import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import {
    AuthMutationsTypes,
    AuthStateTypes,
    AuthUser,
    UserData,
    ConfirmationResult,
    RegisterParams,
    RegisterPartParams,
    UnisCustomer,
    ResetAccount,
    ResetDestination,
    ResetParams,
    LimitedResponse,
    EncryptedResponse,
    EntryParams,
    IdPassParams,
    ForgotRequestParams,
} from './../../interfaces';
import { Conf } from '@/conf';

export const mutations: MutationTree<AuthStateTypes> & AuthMutationsTypes = {
    [MutationTypes.SET_AUTH](state: AuthStateTypes, payload: AuthUser | undefined) {
        state.auth = payload;
    },
    [MutationTypes.SET_AUTH_USER](state: AuthStateTypes, payload: UserData | undefined) {
        state.authUser = payload;
        state.isSalesDemoUser = false;
        if (payload && payload.unis.unisCustomerCode?.includes(Conf.salesDemoUsers)) {
            state.isSalesDemoUser = true;
        }
    },
    [MutationTypes.SET_UNIS_CUSTOMER](state: AuthStateTypes, payload: UnisCustomer | undefined) {
        state.unisCustomer = payload;
    },
    [MutationTypes.SET_CONFIRMATION_RESULT](state: AuthStateTypes, payload: ConfirmationResult | undefined) {
        state.confirmationResult = payload;
    },
    [MutationTypes.SET_REGISTER_PARAMS](state: AuthStateTypes, payload: RegisterParams | undefined) {
        state.registerParams = payload;
    },
    [MutationTypes.SET_REGISTER_PART_PARAMS](state: AuthStateTypes, payload: RegisterPartParams | undefined) {
        state.registerPartParams = payload;
    },
    [MutationTypes.SET_SELECT_RESET_ACCOUNT](state: AuthStateTypes, payload: ResetAccount | undefined) {
        state.resetAccount = payload;
    },
    [MutationTypes.SET_RESET_DESTINATION](state: AuthStateTypes, payload: ResetDestination | undefined) {
        state.resetDestination = payload;
    },
    [MutationTypes.SET_RESET_PARAMS](state: AuthStateTypes, payload: ResetParams | undefined) {
        state.resetParams = payload;
    },
    [MutationTypes.SET_LIMITED_DATA](state: AuthStateTypes, payload: LimitedResponse | undefined) {
        if (payload) {
            state.limitedData = payload;
        }
    },
    [MutationTypes.SET_ENCRYPTED_DATA](state: AuthStateTypes, payload: EncryptedResponse | undefined) {
        if (payload) {
            state.encryptedData = {
                iv: decodeURIComponent(payload.iv),
                data: decodeURIComponent(payload.data),
            };
        }
    },
    [MutationTypes.SET_ENTRY_PARAMS](state: AuthStateTypes, payload: EntryParams | undefined) {
        state.entryParams = payload;
    },
    [MutationTypes.SET_ENTRY_CHALLENGE_UMID](state: AuthStateTypes, payload: string | undefined) {
        state.entryChallengeUmid = payload;
    },
    [MutationTypes.SET_ENTRY_PROVISIONAL_UMID](state: AuthStateTypes, payload: string | undefined) {
        state.entryProvisionalUmid = payload;
    },
    [MutationTypes.SET_ID_PASS_PARAMS](state: AuthStateTypes, payload: IdPassParams | undefined) {
        state.setIdPassParams = payload;
    },
    [MutationTypes.SET_FORGOT_REQUEST_PARAMS](state: AuthStateTypes, payload: ForgotRequestParams | undefined) {
        state.forgotRequestParams = payload;
    },
};
