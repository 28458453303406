/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree } from 'vuex';
import { ActionTypes } from './action-types';
import { MutationTypes } from './mutation-types';
import { TopActionsTypes, TopStateTypes, IRootState, AuthUser } from '@/store/interfaces';
import apiClient from '@/client/api';

export const actions: ActionTree<TopStateTypes, IRootState> & TopActionsTypes = {
    /*
     * 加入サービスリストの取得
     */
    async [ActionTypes.FETCH_SUBSCRIBED_SERVICES]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get('/service/subscribed', {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_SUBSCRIBED_SERVICES, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },
    /*
     * QAサービスリストの取得
     */
    async [ActionTypes.FETCH_QA_SERVICES]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get('/service/qa', {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_QA_SERVICES, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },
    /*
     * 未加入サービスリストの取得
     */
    async [ActionTypes.FETCH_UNSUBSCRIBED_SERVICES]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get('/service/unsubscribed', {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_UNSUBSCRIBED_SERVICES, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * おすすめサービスリストの取得
     */
    async [ActionTypes.FETCH_RECOMMEND_SERVICES]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get('/service/recommend', {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_RECOMMEND_SERVICES, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * おすすめ商品リストの取得
     */
    async [ActionTypes.FETCH_RECOMMEND_CART_ITEMS]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get('/cart/recommend', {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_RECOMMEND_CART_ITEMS, response.data.recommend);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * カートのポイントの取得
     */
    async [ActionTypes.FETCH_CART_POINT]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get('/cart/point', {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_CART_POINT, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * 特集バナーリストの取得
     */
    async [ActionTypes.FETCH_SPECIAL_BANNERS]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get(`/banner/top/special`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_SPECIAL_BANNERS, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * PRバナーリストの取得
     */
    async [ActionTypes.FETCH_PR_BANNERS]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get(`/banner/top/pr`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_PR_BANNERS, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * お知らせリストの取得
     */
    async [ActionTypes.FETCH_INFOS]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get(`/info`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_INFOS, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * お知らせ詳細の取得
     */
    async [ActionTypes.FETCH_INFO_DETAIL]({ commit }, payload: { auth: AuthUser; id: string }) {
        try {
            const response = await apiClient.get(`/info/${payload.id}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            if (response.data) {
                response.data.description = response.data.description.replace(/\n/g, '<br />');
            }
            // console.log(response.data);
            commit(MutationTypes.SET_INFO_DETAIL, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * 天気予報の取得
     */
    async [ActionTypes.FETCH_WEATHER]({ commit }, payload: { auth: AuthUser; area: string; mode: string; from: string; to: string }) {
        try {
            const response = await apiClient.get(`/weather?area=${payload.area}&mode=${payload.mode}&from=${payload.from}&to=${payload.to}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_WEATHER, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },
};
