import { GetterTree } from 'vuex';
import { AccountGettersTypes, AccountStateTypes, IRootState } from './../../interfaces';

export const getters: GetterTree<AccountStateTypes, IRootState> & AccountGettersTypes = {
    brands: (state: AccountStateTypes) => {
        return state.brands;
    },
    area: (state: AccountStateTypes) => {
        return state.area;
    },
    branches: (state: AccountStateTypes) => {
        return state.branches;
    },
    shareMembers: (state: AccountStateTypes) => {
        return state.shareMembers;
    },
    shareAccount: (state: AccountStateTypes) => {
        return state.shareAccount;
    },
    myAccount: (state: AccountStateTypes) => {
        return state.myAccount;
    },
    shareUmid: (state: AccountStateTypes) => {
        return state.umid;
    },
    u2Codes: (state: AccountStateTypes) => {
        const u2Codes = sessionStorage.getItem('u2Codes');
        if (u2Codes) {
            return JSON.parse(u2Codes);
        }
        return state.u2Codes;
    },
    serviceCodes: (state: AccountStateTypes) => {
        const serviceCodes = sessionStorage.getItem('serviceCodes');
        if (serviceCodes) {
            return JSON.parse(serviceCodes);
        }
        return state.serviceCodes;
    },
};
