import { Module } from 'vuex';
import { AccountStateTypes, IRootState } from '@/store/interfaces';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';

// Module
const account: Module<AccountStateTypes, IRootState> = {
    state,
    getters,
    mutations,
    actions,
};

export default account;
