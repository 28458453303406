import { GetterTree } from 'vuex';
import { ContractGettersTypes, ContractStateTypes, IRootState } from './../../interfaces';

export const getters: GetterTree<ContractStateTypes, IRootState> & ContractGettersTypes = {
    docKinds: (state: ContractStateTypes) => {
        return state.docKinds;
    },
    contracts: (state: ContractStateTypes) => {
        return state.contracts;
    },
    contractDoc: (state: ContractStateTypes) => {
        return state.contractDoc;
    },
};
