/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree } from 'vuex';
import { ActionTypes } from './action-types';
import { UPayActionsTypes, UPayStateTypes, IRootState, AuthUser, UPayAppUser, UPayAdminUser } from '@/store/interfaces';
import { AxiosError } from 'axios';
import apiClient from '@/client/api';
import { MutationTypes } from './mutation-types';

export const actions: ActionTree<UPayStateTypes, IRootState> & UPayActionsTypes = {
    /*
     * UPAYアプリID取得
     */
    // eslint-disable-next-line no-empty-pattern
    async [ActionTypes.UPAY_APP_ID]({}, payload: { auth: AuthUser; unisCustomerCode: string }): Promise<string | null> {
        try {
            const response = await apiClient.get(`/upay/app/id?unisCustomerCode=${payload.unisCustomerCode}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            // console.log(response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
    /*
     * UPAYアプリのパスワードリセット
     */
    async [ActionTypes.UPAY_APP_RESET]({ commit }, payload: { auth: AuthUser; unisCustomerCode: string }): Promise<UPayAppUser | null> {
        try {
            const response = await apiClient.get(`/upay/app?unisCustomerCode=${payload.unisCustomerCode}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            // console.log(response.data);
            commit(MutationTypes.SET_UPAY_APP, response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
    /*
     * UPAY ADMINのパスワード
     */
    async [ActionTypes.UPAY_ADMIN]({ commit }, payload: { auth: AuthUser; unisCustomerCode: string }): Promise<UPayAdminUser | null> {
        try {
            const response = await apiClient.get(`/upay/admin?unisCustomerCode=${payload.unisCustomerCode}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            // console.log(response.data);
            commit(MutationTypes.SET_UPAY_ADMIN, response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
    /*
     * UPAYQR APP
     */
    async [ActionTypes.UPAYQR_APP]({ commit }, payload: { auth: AuthUser; unisCustomerCode: string }): Promise<UPayAppUser | null> {
        try {
            const response = await apiClient.get(`/upay/qr/app?unisCustomerCode=${payload.unisCustomerCode}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            // console.log(response.data);
            commit(MutationTypes.SET_UPAY_APP, response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
    /*
     * UPAY ADMINのパスワード
     */
    async [ActionTypes.UPAYQR_ADMIN]({ commit }, payload: { auth: AuthUser; unisCustomerCode: string }): Promise<UPayAdminUser | null> {
        try {
            const response = await apiClient.get(`/upay/qr/admin?unisCustomerCode=${payload.unisCustomerCode}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            // console.log(response.data);
            commit(MutationTypes.SET_UPAY_ADMIN, response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
};
