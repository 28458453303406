import { Module } from 'vuex';
import { UPayStateTypes, IRootState } from '@/store/interfaces';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';

// Module
const upay: Module<UPayStateTypes, IRootState> = {
    state,
    getters,
    mutations,
    actions,
};

export default upay;
