import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { UPayMutationsTypes, UPayStateTypes, UPayAppUser, UPayAdminUser } from './../../interfaces';

export const mutations: MutationTree<UPayStateTypes> & UPayMutationsTypes = {
    [MutationTypes.SET_UPAY_APP](state: UPayStateTypes, payload: UPayAppUser) {
        state.upayAppUser = payload;
    },
    [MutationTypes.SET_UPAY_ADMIN](state: UPayStateTypes, payload: UPayAdminUser) {
        state.upayAdminUser = payload;
    },
};
