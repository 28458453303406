import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { ContractMutationsTypes, ContractStateTypes, ContractListData, ContractDocument } from './../../interfaces';

export const mutations: MutationTree<ContractStateTypes> & ContractMutationsTypes = {
    // [MutationTypes.SET_CONTRACT_KIND_LIST](state: ContractStateTypes, payload: ContractDocument[]) {
    //     state.docKinds = payload;
    // },
    [MutationTypes.SET_CONTRACT_LIST](state: ContractStateTypes, payload: ContractListData | undefined) {
        state.contracts = payload;
    },
    [MutationTypes.SET_CONTRACT_DOC](state: ContractStateTypes, payload: ContractDocument | undefined) {
        state.contractDoc = payload;
    },
};
