import { ActionTree } from 'vuex';
import { ActionTypes } from './action-types';
import { MutationTypes } from './mutation-types';
import { InvoiceActionsTypes, InvoiceStateTypes, IRootState, AuthUser } from '@/store/interfaces';
import apiClient from '@/client/api';

export const actions: ActionTree<InvoiceStateTypes, IRootState> & InvoiceActionsTypes = {
    /*
     * 合計
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.INVOICE_TOTAL]({ commit }, payload: { auth: AuthUser; from: string; to: string }) {
        try {
            const response = await apiClient.get(`/invoice?from=${payload.from}&to=${payload.to}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            // console.log(response.data);
            commit(MutationTypes.SET_TOTAL, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * サマリ
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.INVOICE_SUMMARY]({ commit }, payload: { auth: AuthUser; invoiceId: string }) {
        try {
            const response = await apiClient.get(`/invoice/${payload.invoiceId}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            // console.log(`/invoice/${payload.invoiceId}`);
            // console.log(response.data);
            commit(MutationTypes.SET_SUMMARY, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },
};
