import { Module } from 'vuex';
import { ContractStateTypes, IRootState } from '@/store/interfaces';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';

// Module
const contract: Module<ContractStateTypes, IRootState> = {
    state,
    getters,
    mutations,
    actions,
};

export default contract;
