import { ActionTree } from 'vuex';
import { ActionTypes } from './action-types';
import { MutationTypes } from './mutation-types';
import {
    AccountActionsTypes,
    AccountStateTypes,
    IRootState,
    AuthUser,
    CreateShareAccountParam,
    UpdateShareAccountParam,
    UpdateMailParam,
} from '@/store/interfaces';
import apiClient from '@/client/api';
import { AxiosError } from 'axios';

export const actions: ActionTree<AccountStateTypes, IRootState> & AccountActionsTypes = {
    /*
     * アクティブな契約情報の取得
     */
    async [ActionTypes.FETCH_U2_ACTIVE]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get(`/u2/active`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            // console.log(response.data);
            commit(MutationTypes.SET_U2_ACTIVE, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },
    /*
     * ブランド情報リストの取得
     */
    async [ActionTypes.FETCH_BRANDS]({ commit }, payload: { auth: AuthUser; companyCode: string }) {
        try {
            const response = await apiClient.get(`/brand?companyCode=${payload.companyCode}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_BRANDS, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * エリア情報の取得
     */
    async [ActionTypes.FETCH_AREA]({ commit }, payload: { auth: AuthUser; brandCode: string }) {
        try {
            const response = await apiClient.get(`/area?brandCode=${payload.brandCode}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_AREA, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * 支店情報リストの取得
     */
    async [ActionTypes.FETCH_BRANCHES]({ commit }, payload: { auth: AuthUser; brandCode: string }) {
        try {
            const response = await apiClient.get(`/branch?brandCode=${payload.brandCode}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_BRANCHES, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * シェアメンバーリストの取得
     */
    async [ActionTypes.FETCH_SHARE_MEMBERS]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get('/share', {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_SHARE_MEMBERS, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * 支店ごとのシェアメンバーリストの取得
     */
    async [ActionTypes.FETCH_SHARE_BRANCHES]({ commit }, payload: { auth: AuthUser; keyword: string; unisCustomerCode: string }) {
        try {
            const response = await apiClient.get(`/share/branch?keyword=${payload.keyword}&unisCustomerCode=${payload.unisCustomerCode}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_BRANCHES, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * シェアアカウント詳細の取得
     */
    async [ActionTypes.FETCH_SHARE_ACCOUNT]({ commit }, payload: { auth: AuthUser; umid: string }) {
        try {
            const response = await apiClient.get(`/share/${payload.umid}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_SHARE_ACCOUNT, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * シェアアカウントの追加
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.CREATE_SHARE_ACCOUNT]({ commit }, payload: { auth: AuthUser; param: CreateShareAccountParam }) {
        try {
            await apiClient.post('/share', payload.param, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * シェアアカウントの更新
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.UPDATE_SHARE_ACCOUNT]({ commit }, payload: { auth: AuthUser; param: UpdateShareAccountParam }) {
        try {
            await apiClient.put('/share', payload.param, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * マイアカウント詳細の取得
     */
    async [ActionTypes.FETCH_MY_ACCOUNT]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get(`/account/${payload.auth.umid}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            // console.log(response.data);
            commit(MutationTypes.SET_MY_ACCOUNT, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * マイアカウントのメールアドレス更新
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.UPDATE_MY_ACCOUNT_MAIL]({ commit }, payload: { auth: AuthUser; param: UpdateMailParam }): Promise<number> {
        try {
            await apiClient.put('/account/my/mail', payload.param, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            return 200;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            if (err.response?.status === 404) {
                console.error(err.response?.status);
                return 404;
            }
            return 500;
        }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.VERIFY_MAIL_COMPLETE]({ commit }, payload: { umid: string }): Promise<number> {
        try {
            await apiClient.put('/account/my/mail/verify', payload.umid, {
                headers: {
                    'x-umid': payload.umid,
                },
            });
            return 200;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            if (err.response?.status === 404) {
                console.error(err.response?.status);
                return 404;
            }
            return 500;
        }
    },
};
