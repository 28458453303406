import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { PaymentMutationsTypes, PaymentStateTypes, PaymentData } from './../../interfaces';

export const mutations: MutationTree<PaymentStateTypes> & PaymentMutationsTypes = {
    [MutationTypes.SET_PAYMENT_LIST](state: PaymentStateTypes, payload: { [key: string]: PaymentData[] }) {
        state.paymentList = payload;
    },
    [MutationTypes.SET_PAYMENT_DETAIL](state: PaymentStateTypes, payload: PaymentData) {
        state.paymentDetail = payload;
    },
    [MutationTypes.SET_PAYMENT_SOUKI_DETAIL](state: PaymentStateTypes, payload: PaymentData[]) {
        state.paymentDetails = payload;
        if (payload.length > 0) {
            state.paymentSummary = payload[0];
        }
    },
};
