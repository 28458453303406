import { AccountStateTypes } from './../../interfaces';

export const state: AccountStateTypes = {
    brands: [],
    area: undefined,
    branches: [],
    shareMembers: [],
    u2Codes: [],
    serviceCodes: [],
    shareAccount: undefined,
    myAccount: undefined,
    umid: undefined,
};
