import { GetterTree } from 'vuex';
import { TopGettersTypes, TopStateTypes, IRootState } from './../../interfaces';

export const getters: GetterTree<TopStateTypes, IRootState> & TopGettersTypes = {
    subscribedServices: (state: TopStateTypes) => {
        return state.subscribedServices;
    },
    qaServices: (state: TopStateTypes) => {
        return state.qaServices;
    },
    unsubscribedServices: (state: TopStateTypes) => {
        return state.unsubscribedServices;
    },
    recommendServices: (state: TopStateTypes) => {
        return state.recommendServices;
    },
    recommendCartItems: (state: TopStateTypes) => {
        return state.recommendCartItems;
    },
    cartPoint: (state: TopStateTypes) => {
        return state.cartPoint;
    },
    specialBanners: (state: TopStateTypes) => {
        return state.specialBanners;
    },
    prBanners: (state: TopStateTypes) => {
        return state.prBanners;
    },
    infos: (state: TopStateTypes) => {
        return state.infos;
    },
    infoDetail: (state: TopStateTypes) => {
        return state.infoDetail;
    },
    weather: (state: TopStateTypes) => {
        return state.weather;
    },
};
