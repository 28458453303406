import axios from 'axios';
import axiosRetry from 'axios-retry';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
});

axiosRetry(apiClient, { retries: 3 });

export default apiClient;
