import { GetterTree } from 'vuex';
import { PaymentGettersTypes, PaymentStateTypes, IRootState } from '../../interfaces';

export const getters: GetterTree<PaymentStateTypes, IRootState> & PaymentGettersTypes = {
    paymentList: (state: PaymentStateTypes) => {
        return state.paymentList;
    },
    paymentDetail: (state: PaymentStateTypes) => {
        return state.paymentDetail;
    },
    paymentDetails: (state: PaymentStateTypes) => {
        return state.paymentDetails;
    },
    paymentSummary: (state: PaymentStateTypes) => {
        return state.paymentSummary;
    },
};
