export enum MutationTypes {
    SET_BRANDS = 'SET_BRANDS',
    SET_AREA = 'SET_AREA',
    SET_BRANCHES = 'SET_BRANCHES',
    SET_SHARE_MEMBERS = 'SET_SHARE_MEMBERS',
    SET_SHARE_ACCOUNT = 'SET_SHARE_ACCOUNT',
    SET_MY_ACCOUNT = 'SET_MY_ACCOUNT',
    SET_SHARE_UMID = 'SET_SHARE_UMID',
    SET_U2_ACTIVE = 'SET_U2_ACTIVE',
}
