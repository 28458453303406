import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { AccountMutationsTypes, AccountStateTypes, Brand, Area, Branch, UserData, MyAccount, ShareAccount, ActiveContract } from './../../interfaces';
import { Conf } from '@/conf';

export const mutations: MutationTree<AccountStateTypes> & AccountMutationsTypes = {
    [MutationTypes.SET_BRANDS](state: AccountStateTypes, payload: Brand[]) {
        state.brands = payload;
    },
    [MutationTypes.SET_AREA](state: AccountStateTypes, payload: Area | undefined) {
        state.area = payload;
    },
    [MutationTypes.SET_BRANCHES](state: AccountStateTypes, payload: Branch[]) {
        state.branches = payload;
    },
    [MutationTypes.SET_SHARE_MEMBERS](state: AccountStateTypes, payload: UserData[]) {
        if (payload) {
            for (const item of payload) {
                if (item.account && item.account.userGrant) {
                    const target = item.account.userGrant.slice(-1);
                    // eslint-disable-next-line
                    const shareUserGrantDefine = Conf.shareUserGrantDefine.find((v: any) => v.value === target);
                    item.account.userGrantName = shareUserGrantDefine.name;
                }
            }
        }
        state.shareMembers = payload;
    },
    [MutationTypes.SET_SHARE_ACCOUNT](state: AccountStateTypes, payload: ShareAccount | undefined) {
        state.shareAccount = payload;
    },
    [MutationTypes.SET_MY_ACCOUNT](state: AccountStateTypes, payload: MyAccount | undefined) {
        state.myAccount = payload;
    },
    [MutationTypes.SET_SHARE_UMID](state: AccountStateTypes, payload: string | undefined) {
        state.umid = payload;
    },
    [MutationTypes.SET_U2_ACTIVE](state: AccountStateTypes, payload: ActiveContract) {
        state.u2Codes = payload.u2;
        state.serviceCodes = payload.serviceCode;
        // console.log(payload);
        sessionStorage.setItem('u2Codes', JSON.stringify(payload.u2));
        sessionStorage.setItem('serviceCodes', JSON.stringify(payload.serviceCode));
    },
};
