import { InvoiceStateTypes } from './../../interfaces';

export const state: InvoiceStateTypes = {
    total: [],
    payment: undefined,
    summary: undefined,
    discount: undefined,
    invoiceId: undefined,
    monthlyPayment: undefined,
};
