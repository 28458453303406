import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { InvoiceMutationsTypes, InvoiceStateTypes, InvoiceTotalData, Payment } from './../../interfaces';

export const mutations: MutationTree<InvoiceStateTypes> & InvoiceMutationsTypes = {
    [MutationTypes.SET_TOTAL](state: InvoiceStateTypes, payload: InvoiceTotalData[]) {
        state.total = payload;
        if (payload != null && payload.length > 0 && payload[0].payments && payload[0].payments.length > 0) {
            state.payment = payload[0].payments[0];
        }
    },
    // eslint-disable-next-line
    [MutationTypes.SET_SUMMARY](state: InvoiceStateTypes, payload: any | undefined) {
        state.summary = payload.plain;
        state.discount = payload.discount;
    },
    [MutationTypes.SET_INVOICE_ID](state: InvoiceStateTypes, payload: string | undefined) {
        state.invoiceId = payload;
    },
    [MutationTypes.SET_MONTHLY_PAYMENT](state: InvoiceStateTypes, payload: Payment | undefined) {
        state.monthlyPayment = payload;
    },
};
