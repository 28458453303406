import { GetterTree } from 'vuex';
import { AuthGettersTypes, AuthStateTypes, IRootState } from './../../interfaces';

export const getters: GetterTree<AuthStateTypes, IRootState> & AuthGettersTypes = {
    auth: (state: AuthStateTypes) => {
        return state.auth;
    },
    authUser: (state: AuthStateTypes) => {
        return state.authUser;
    },
    isSalesDemoUser: (state: AuthStateTypes) => {
        return state.isSalesDemoUser;
    },
    confirmationResult: (state: AuthStateTypes) => {
        return state.confirmationResult;
    },
    registerParams: (state: AuthStateTypes) => {
        return state.registerParams;
    },
    registerPartParams: (state: AuthStateTypes) => {
        return state.registerPartParams;
    },
    resetAccount: (state: AuthStateTypes) => {
        return state.resetAccount;
    },
    resetDestination: (state: AuthStateTypes) => {
        return state.resetDestination;
    },
    resetParams: (state: AuthStateTypes) => {
        return state.resetParams;
    },
    limitedData: (state: AuthStateTypes) => {
        return state.limitedData;
    },
    encryptedData: (state: AuthStateTypes) => {
        return state.encryptedData;
    },
    entryParams: (state: AuthStateTypes) => {
        return state.entryParams;
    },
    entryChallengeUmid: (state: AuthStateTypes) => {
        return state.entryChallengeUmid;
    },
    entryProvisionalUmid: (state: AuthStateTypes) => {
        return state.entryProvisionalUmid;
    },
    setIdPassParams: (state: AuthStateTypes) => {
        return state.setIdPassParams;
    },
    forgotRequestParams: (state: AuthStateTypes) => {
        return state.forgotRequestParams;
    },
};
