import { GetterTree } from 'vuex';
import { InvoiceGettersTypes, InvoiceStateTypes, IRootState } from './../../interfaces';

export const getters: GetterTree<InvoiceStateTypes, IRootState> & InvoiceGettersTypes = {
    total: (state: InvoiceStateTypes) => {
        return state.total;
    },
    payment: (state: InvoiceStateTypes) => {
        return state.payment;
    },
    summary: (state: InvoiceStateTypes) => {
        return state.summary;
    },
    discount: (state: InvoiceStateTypes) => {
        return state.discount;
    },
    invoiceId: (state: InvoiceStateTypes) => {
        return state.invoiceId;
    },
    monthlyPayment: (state: InvoiceStateTypes) => {
        return state.monthlyPayment;
    },
};
