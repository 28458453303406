import { ContractStateTypes } from './../../interfaces';

export const state: ContractStateTypes = {
    docKinds: [],
    contracts: undefined,
    contractDoc: undefined,
    // contracts: {
    //     application: [
    //         {
    //             ymd: '2022年09月12日',
    //             unisCustomerCode: '010086995',
    //             kind: 'application',
    //             name: 'USENサービス加入申込書 著作物使用許諾申込書',
    //             key: 'application/317349_010086995_20220912_moushikomisho.pdf',
    //             caseId: '317349',
    //             revision: '0',
    //         },
    //         {
    //             ymd: '2022年09月12日',
    //             unisCustomerCode: '010086995',
    //             kind: 'hitosara',
    //             name: 'ヒトサラ加盟店登録及び即時予約機能申込書 兼 関連サービス申込書',
    //             key: 'application/317349_010086995_20220912_moushikomisho.pdf',
    //             caseId: '317349',
    //             revision: '0',
    //         },
    //     ],
    //     delivery: [
    //         {
    //             ymd: '2022年09月12日',
    //             unisCustomerCode: '010086995',
    //             kind: 'nouhin',
    //             name: '納品書・物品受領書',
    //             key: 'application/317349_010086995_20220912_moushikomisho.pdf',
    //             caseId: '317349',
    //             revision: '0',
    //         },
    //         {
    //             ymd: '2022年09月12日',
    //             unisCustomerCode: '010086995',
    //             kind: 'hitosara',
    //             name: '納品書・物品受領書',
    //             key: 'application/317349_010086995_20220912_moushikomisho.pdf',
    //             caseId: '317349',
    //             revision: '0',
    //         },
    //     ],
    //     contract: [
    //         {
    //             ymd: '2022年09月12日',
    //             unisCustomerCode: '010086995',
    //             kind: 'contract',
    //             name: 'ご契約内容確認書',
    //             key: 'application/317349_010086995_20220912_moushikomisho.pdf',
    //             caseId: '317349',
    //             revision: '0',
    //         },
    //         {
    //             ymd: '2022年09月12日',
    //             unisCustomerCode: '010086995',
    //             kind: 'contract',
    //             name: 'ご契約内容確認書',
    //             key: 'application/317349_010086995_20220912_moushikomisho.pdf',
    //             caseId: '317349',
    //             revision: '0',
    //         },
    //     ],
    // },
    // contractDoc: {
    //     ymd: '2022年09月12日',
    //     unisCustomerCode: '010086995',
    //     kind: 'application',
    //     name: 'USENサービス加入申込書 著作物使用許諾申込書',
    //     key: 'application/317349_010086995_20220912_moushikomisho.pdf',
    //     caseId: '317349',
    //     revision: '0',
    // },
};
