import { ActionTree } from 'vuex';
import { ActionTypes } from './action-types';
import { MutationTypes } from './mutation-types';
import { ContractActionsTypes, ContractStateTypes, IRootState, AuthUser } from '@/store/interfaces';
import apiClient from '@/client/api';

export const actions: ActionTree<ContractStateTypes, IRootState> & ContractActionsTypes = {
    /*
     * 契約書一覧
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // async [ActionTypes.FETCH_CONTRACT_KIND_LIST]({ commit }, payload: { auth: AuthUser; kind: string }) {
    //     try {
    //         const response = await apiClient.get(`/contract`, {
    //             headers: {
    //                 Authorization: payload.auth.idToken,
    //                 'x-umid': payload.auth.umid,
    //             },
    //         });
    //         // console.log(response.data);
    //         commit(MutationTypes.SET_CONTRACT_KIND_LIST, response.data);
    //     } catch (error) {
    //         console.error(error);
    //         // TODO: エラーメッセージ表示
    //     }
    // },
    /*
     * 契約書一覧種別ごと
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.FETCH_CONTRACT_LIST]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.get(`/contract/list`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            console.log(response.data);
            commit(MutationTypes.SET_CONTRACT_LIST, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },
};
