import { ActionTypes as authTypes } from './modules/auth/action-types';
import { ActionTypes as topTypes } from './modules/top/action-types';
import { ActionTypes as accountTypes } from './modules/account/action-types';
import { ActionTypes as inquiryTypes } from './modules/inquiry/action-types';
import { ActionTypes as invoiceTypes } from './modules/invoice/action-types';
import { ActionTypes as contractTypes } from './modules/contract/action-types';
import { ActionTypes as upayTypes } from './modules/upay/action-types';
import { ActionTypes as paymentTypes } from './modules/payment/action-types';

export const AllActionTypes = {
    ...authTypes,
    ...topTypes,
    ...accountTypes,
    ...inquiryTypes,
    ...invoiceTypes,
    ...contractTypes,
    ...upayTypes,
    ...paymentTypes,
};
