/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree } from 'vuex';
import { ActionTypes } from './action-types';
import { IRootState, AuthUser, PaymentActionsTypes, PaymentStateTypes, PaymentData, PaymentList } from '@/store/interfaces';
import { AxiosError } from 'axios';
import apiClient from '@/client/api';
import { MutationTypes } from './mutation-types';

export const actions: ActionTree<PaymentStateTypes, IRootState> & PaymentActionsTypes = {
    /*
     * 入金一覧取得
     */
    // eslint-disable-next-line no-empty-pattern
    async [ActionTypes.PAYMENT_LIST]({ commit }, payload: { auth: AuthUser; from: string; to: string }): Promise<PaymentList[] | null> {
        try {
            if (payload.from === '' && payload.to === '') {
                const response = await apiClient.get(`/payment`, {
                    headers: {
                        Authorization: payload.auth.idToken,
                        'x-umid': payload.auth.umid,
                    },
                });
                commit(MutationTypes.SET_PAYMENT_LIST, response.data);
                return response.data;
            } else {
                const response = await apiClient.get(`/payment?from=${payload.from}&to=${payload.to}`, {
                    headers: {
                        Authorization: payload.auth.idToken,
                        'x-umid': payload.auth.umid,
                    },
                });
                commit(MutationTypes.SET_PAYMENT_LIST, response.data);
                return response.data;
            }
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
    /*
     * 入金詳細詳細
     */
    async [ActionTypes.PAYMENT_DETAIL]({ commit }, payload: { auth: AuthUser; id: string }): Promise<PaymentData | null> {
        try {
            const response = await apiClient.get(`/payment/${payload.id}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            console.log(response.data);
            commit(MutationTypes.SET_PAYMENT_DETAIL, response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
    async [ActionTypes.PAYMENT_SOUKI_DETAIL]({ commit }, payload: { auth: AuthUser; id: string }): Promise<PaymentData | null> {
        try {
            const response = await apiClient.get(`/payment/${payload.id}`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            console.log(response.data);
            commit(MutationTypes.SET_PAYMENT_SOUKI_DETAIL, response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
    async [ActionTypes.PAYMENT_PDF_PREVIEW]({ commit }, payload: { umid: string; id: string }): Promise<PaymentData | null> {
        try {
            const response = await apiClient.get(`/payment/pdf/${payload.id}?umid=${payload.umid}`, {
                headers: {
                    'x-api-key': process.env.VUE_APP_API_KEY ?? '',
                    'x-umid': payload.umid,
                },
            });
            console.log(response.data);
            commit(MutationTypes.SET_PAYMENT_DETAIL, response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
    async [ActionTypes.PAYMENT_SOUKI_PDF_PREVIEW]({ commit }, payload: { id: string }): Promise<PaymentData | null> {
        try {
            const response = await apiClient.get(`/payment/pdf/${payload.id}`, {
                headers: {
                    'x-api-key': process.env.VUE_APP_API_KEY ?? '',
                },
            });
            console.log(response.data);
            commit(MutationTypes.SET_PAYMENT_SOUKI_DETAIL, response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return null;
        }
    },
    async [ActionTypes.PAYMENT_PDF_DOWNLOAD](
        { commit },
        payload: { auth: AuthUser; paymentId: string; type: string; unisCustomerCode: string }
    ): Promise<string> {
        try {
            const response = await apiClient.get(
                `/pdf/payment/download?id=${payload.paymentId}&unisCustomerCode=${payload.unisCustomerCode}&type=${payload.type}`,
                {
                    headers: {
                        Authorization: payload.auth.idToken,
                        'x-umid': payload.auth.umid,
                    },
                }
            );
            console.log(`/pdf/payment/download?id=${payload.paymentId}&unisCustomerCode=${payload.unisCustomerCode}&type=${payload.type}`);
            console.log(response.data);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            return '';
        }
    },
};
